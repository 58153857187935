<template>
  <v-card>
    <v-toolbar color="color2 color2Text--text">
      <v-toolbar-title>Cart Fee Editor</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="color1 color1Text--text" @click.stop="cancel" small fab>
        <v-icon>fas fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-container fluid v-if="fee">
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Fee Label"
              v-model="fee.label"
              :disabled="!editing"
            ></v-text-field>
          </v-col>
          <v-col cols="3" sm="2">
            <v-select
              label="Fee Type"
              :items="['$', '%']"
              v-model="feeType"
              :disabled="!editing"
            ></v-select>
          </v-col>
          <v-col cols="9" sm="4">
            <v-text-field
              :label="`Fee ${this.feeType === '$' ? 'Amount' : 'Percentage'}`"
              v-model="amount"
              :disabled="!editing"
              type="number"
              @blur="formatAmount"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Fee Description"
              v-model="fee.description"
              :disabled="!editing"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              label="Is Tax"
              v-model="fee.isTax"
              :disabled="!editing"
              hide-details
              color="success"
              class="mt-0"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex">
            <v-switch
              label="Fee is per player"
              v-model="fee.perPlayer"
              small
              :disabled="!editing"
              color="success"
              class="shrink mr-2"
            ></v-switch>
            <v-switch
              label="Can be discounted"
              v-model="fee.promoable"
              small
              :disabled="!editing"
              color="success"
              class="mr-2 shrink"
            ></v-switch>
          </v-col>
          <v-col cols="12" class="d-flex" v-if="membershipPrefix">
            <v-switch
              label="Non-Members Only"
              v-model="fee.nonMembers"
              :value="membershipPrefix"
              small
              :disabled="!editing"
              color="success"
              class="mr-2 shrink"
            ></v-switch>
            <v-switch
              label="Members Only"
              v-model="fee.members"
              :value="membershipPrefix"
              small
              :disabled="!editing"
              color="success"
              class="mr-2 shrink"
            ></v-switch>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="justify-end">
      <v-fab-transition>
        <v-btn
          :key="valid"
          v-if="editing"
          color="success"
          @click="save"
          :disabled="!valid"
          small fab
        >
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-fab-transition>
        <v-btn
          :key="button.key"
          :color="button.color"
          @click="button.click"
          small fab
        >
          <v-icon>{{button.icon}}</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card-actions>
  </v-card>
</template>

<script>
import CartFee from '@/classes/CartFee'
import { fixed2, fixed3, fixed2OrInt } from '@/Filters'

export default {
  props: ['cartFee', 'startInEdit', 'active', 'tournament'],
  data () {
    return {
      fee: null,
      editing: false,
      feeType: '$'
    }
  },
  computed: {
    button () {
      return this.editing ? {
        key: 'cancel',
        icon: 'fas fa-times',
        color: 'error',
        click: this.cancel
      } : {
        key: 'edit',
        icon: 'fas fa-pencil',
        color: 'color3Text color3--text',
        click: this.edit
      }
    },
    amount: {
      get () {
        return this.feeType === '$' ? this.fee.amount : fixed2OrInt(this.fee.percentage * 100)
      },
      set (v) {
        if (this.feeType === '$') {
          this.fee.amount = v
          this.fee.percentage = null
        } else {
          this.fee.amount = null
          this.fee.percentage = +v / 100
        }
      }
    },
    dirty () {
      return this.fee && JSON.stringify(this.fee.dto) !== JSON.stringify(this.cartFee)
    },
    valid () {
      return this.dirty && this.fee.valid
    },
    membershipPrefix () {
      var orgMem = this.tournament.organization.membershipPrefix && this.tournament.organization.membershipPrefix.toLowerCase()
      var existMem = this.fee.nonMembers && this.fee.nonMembers.length ? this.fee.nonMembers[0] : this.fee.members && this.fee.members.length ? this.fee.members[0] : false
      return orgMem || existMem
    }
  },
  methods: {
    init () {
      const dto = this.cartFee ? JSON.stringify(this.cartFee) : null
      this.feeType = '$'
      if (this.cartFee && this.cartFee.percentage) this.feeType = '%'
      this.editing = !!this.startInEdit
      this.fee = new CartFee(dto)
      this.formatAmount()
    },
    edit () {
      this.init()
      this.editing = true
    },
    cancel () {
      this.$emit('cancel-click')
      this.init()
      this.editing = false
    },
    save () {
      if (this.valid) {
        this.$emit('save-click', this.fee.dto)
      }
    },
    formatAmount () {
      if (this.fee.amount) this.fee.amount = fixed2(this.fee.amount)
      if (this.fee.percentage) this.fee.percentage = fixed3(this.fee.percentage)
    }
  },
  watch: {
    active: 'init'
  },
  mounted () {
    this.init()
  }
}
</script>
